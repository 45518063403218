import ReactDOMServer from 'react-dom/server';

export const cardData = [
  {
    title: 'Go Green',
    key: 'go-green',
  },
  {
    title: 'Regulations',
    key: 'regulation',
  },
  {
    title: 'Guidelines',
    key: 'guideline',
  },
  {
    title: 'Construction Drawings',
    key: 'construction-drawing',
  },
  {
    title: 'Building System',
    key: 'building-system',
  },
];

export const findDocumentGroupById = (tree, id) => {
  if (tree.refId === id) {
    return tree;
  }

  if (tree.children && tree.children.length > 0) {
    for (const child of tree.children) {
      const result = findDocumentGroupById(child, id);
      if (result) {
        return result;
      }
    }
  }

  return null;
};

export const flattenWithParentId = (data, parentId = null) => {
  let result = [];

  const current = { ...data, id: data.refId, parentId };
  delete current.children;
  result.push(current);

  if (data.children && data.children.length > 0) {
    for (const child of data.children) {
      result = result.concat(flattenWithParentId(child, data.refId));
    }
  }

  return result;
};

export const toggleDropdown = (e) => {
  const dropdownContent = e.target.nextElementSibling;
  const isVisible = dropdownContent.style.display === 'block';

  dropdownContent.style.display = isVisible ? 'none' : 'block';
};

export const closeDropdown = (e) => {
  if (!e.target.matches('.chart-dropdown-button')) {
    document.querySelectorAll('.chart-dropdown-content').forEach((dropdown) => {
      dropdown.style.display = 'none';
    });
  }
};

export const generateNodeHTML = (d) => {
  const { id, type, parentId, isTopic } = d.data;
  const isRootNode = !parentId;

  console.log('d', d.data);

  return `
    <div class="chart-node-wrapper" style="width: ${d.width}px; height: ${d.height}px;" id="${d.data.id}">
      <div style="position: absolute; top: -14px; right: 4px;">
        <button class="chart-dropdown-button">Menu</button>
        <div class="chart-dropdown-content" style="display: none;">
          <button class="chart-dropdown-action" data-ref-id="${id}" data-action-type="edit" data-type="${type}">
            Edit
          </button>
          ${
            type === 'document-group'
              ? `
            <button class="chart-dropdown-action" data-ref-id="${id}" data-action-type="add-child-group" data-type="${type}">
              Add child group
            </button>
           
            <button class="chart-dropdown-action" data-ref-id="${id}" data-action-type="add-child-document" data-type="${type}">
              Add child document
            </button>
             
            ${
              isTopic
                ? `
             <button class="chart-dropdown-action" data-ref-id="${id}" data-action-type="remove-topic" data-type="${type}">
             Remove Topic
            </button>
            `
                : `
            <button class="chart-dropdown-action" data-ref-id="${id}" data-action-type="mark-as-topic" data-type="${type}">
             Mark As Topic
            </button>
            `
            }
            ${
              !isRootNode
                ? `
                <button class="chart-dropdown-action" data-ref-id="${id}" data-action-type="delete-child-group" data-type="${type}">
                  Delete document group
                </button>
                <button class="chart-dropdown-action" data-ref-id="${id}" data-action-type="pin" data-type="${type}">
                  Pin to top
                </button>
                `
                : ''
            }
          `
              : type === 'document'
                ? `
                <button class="chart-dropdown-action" data-ref-id="${id}" data-action-type="delete-document" data-type="${type}">
                  Delete document
                </button>
                `
                : ''
          }
        </div>
      </div>

      <div style="
        font-family: Inter, sans-serif;
        margin-left: -1px;
        width: ${d.width - 2}px;
        height: ${d.height}px;
        border-radius: 4px;
        padding: 10px;
        background-color: #E5E5E5;
        border: ${d.data._highlighted || d.data._upToTheRootHighlighted ? '5px solid #E27396' : '1px solid #6d6d6d'};
      ">
        <div style="font-size: 15px; color: #08011E;">${d.data.title}</div>
        <div style="color: #303030; margin-top: 3px; font-size: 10px;">
          Position: ${d.data.position || 0}
        </div>
        <div style="
          background-color: ${d.data.status === 'ACTIVE' ? '#4F9435' : '#c7c7c7'};
          color: ${d.data.status === 'ACTIVE' ? 'white' : 'black'};
          border-radius: 4px;
          padding: 2px 4px;
          position: absolute;
          bottom: 2px;
          left: 4px;
          font-size: 10px;
        ">
          ${d.data.status === 'ACTIVE' ? 'Active' : 'Draft'}
        </div>
        ${
          isTopic
            ? `
        <div style="
          background-color: #FFD700;
          color: black;
          border-radius: 4px;
          padding: 2px 4px;
          position: absolute;
          top: 2px;
          right: 4px;
          font-size: 10px;
        ">
          Topic Added
        </div>
        `
            : ''
        }
      </div>
      <div style="
        background-color: #595959;
        color: white;
        border-radius: 4px;
        padding: 2px 4px;
        position: absolute;
        bottom: 2px;
        right: 4px;
        font-size: 10px;
      ">
        ${d.data.type === 'document-group' ? 'Group' : 'Document'}
      </div>
    </div>
  `;
};
