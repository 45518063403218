import { ConfigType } from '@/utils/requestAPI';

export type DocumentGroupType = {
  title: string;
  order?: number;
  status?: string;
  level?: string;
  slug?: string;
  parentId?: string;
  image?: string;
  position?: number;

  region?: string[];
  statusChange?: boolean;
};

export type DocumentType = {
  title: string;
  documentGroupId: string;
  region: string[];
  slug: string;

  body: {
    content: string;
    contentType: string;
  };
  type: string;
  hashTags: any;
  position: number;
  status: string;
};
export type PaginationType = {
  limit?: number;
  total?: number;
  totalPages?: number;
  currentPage?: number;
  page?: number;
  search?: string;
  filter?: any;
};

export type DocumentGroupParamsType = {
  parentId?: string;
  region?: string;
  search?: string;
  limit?: number;
  filter?: {
    type: string;
  };
};

export type DocumentParamsType = {
  documentGroupId: string;
  fullSlug: string;
};

const documents = {
  addDocument: (data: DocumentType): ConfigType => {
    return {
      method: 'post',
      url: '/document',
      data,
    };
  },
  deleteDocument: (docId: string): ConfigType => {
    return {
      method: 'delete',
      url: `/document/${docId}`,
    };
  },
  deleteChildDocuments: (groupId: string): ConfigType => {
    return {
      method: 'delete',
      url: `/document-group/${groupId}`,
    };
  },
  getAllDocument: (params?: PaginationType): ConfigType => {
    return {
      method: 'get',
      url: '/document',
      params,
    };
  },
  getSingleDocument: (id: string): ConfigType => {
    return {
      method: 'get',
      url: `/document/${id}`,
    };
  },
  getSingleDocumentParent: (id: string): ConfigType => {
    return {
      method: 'get',
      url: `/documents/${id}/parents`,
    };
  },
  updateDocument: (id: string, data: DocumentType): ConfigType => {
    return {
      method: 'put',
      url: `/document/${id}`,
      data,
    };
  },
  addDocumentGroup: (data: DocumentGroupType): ConfigType => {
    return {
      method: 'post',
      url: '/document-group',
      data,
    };
  },
  updateDocumentGroup: (id: string, data: DocumentGroupType): ConfigType => {
    return {
      method: 'put',
      url: `/document-group/${id}`,
      data,
    };
  },
  getSingleDocumentGroup: (id: string): ConfigType => {
    return {
      method: 'get',
      url: `/document-group/${id}`,
    };
  },
  getAllDocumentGroup: (params?: DocumentGroupParamsType): ConfigType => {
    return {
      method: 'get',
      url: `/document-groups`,
      params,
    };
  },
  getSingleDocumentGroupParent: (id: string): ConfigType => {
    return {
      method: 'get',
      url: `/document-groups/${id}/parents`,
    };
  },
  getAllDocumentWithAnalytics: (params?: PaginationType): ConfigType => {
    return {
      method: 'get',
      url: '/documents/analytics',
      params,
    };
  },
  markAsATopic: (
    { documentGroupId, fullSlug }: DocumentParamsType,
    config = {}
  ): ConfigType => {
    return {
      method: 'post',
      url: `/documents/topics/mark`,
      params: { documentGroupId, fullSlug },
      ...config,
    };
  },
  removeATopic: (
    { documentGroupId, fullSlug }: DocumentParamsType,
    config = {}
  ): ConfigType => {
    return {
      method: 'delete',
      url: `/documents/topics/remove`,
      params: { documentGroupId, fullSlug },
      ...config,
    };
  },
};

export default documents;
