import * as Yup from 'yup';

// import { StoryType } from '@/apiConfigs/stories';

const createStoryValidationSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  body: Yup.string().required('Body is required'),
  // storyType: Yup.mixed<StoryType>()
  //   .oneOf(Object.values(StoryType), 'Invalid story type')
  //   .required('Story type is required'),
  // category: Yup.array()
  //   .of(Yup.string().required('Category is required'))
  //   .min(1, 'At least one category is required')
  //   .required('Category is required'),
});

export { createStoryValidationSchema };
