import { useEffect, useState } from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styled from 'styled-components';

import requestAPI from '@/utils/requestAPI';

const Container = styled.div`
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: white;
`;

const Title = styled.h2`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
`;

const Select = styled.select`
  padding: 8px;
  margin-bottom: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  cursor: pointer;
`;

const ProductChart = () => {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState<string>('last7days');
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState<string>('');
  const [totalValues, setTotalValues] = useState({
    views: 0,
    clicks: 0,
    conversionRate: 0,
  });

  const getSuppliers = async () => {
    try {
      const response = await requestAPI({
        method: 'get',
        url: '/supplier',
        params: { limit: 100 },
      });
      setSuppliers(response?.data);
    } catch (err) {
      console.error('Failed to get suppliers:', err);
    }
  };

  useEffect(() => {
    getSuppliers();
  }, []);

  const fetchData = async () => {
    try {
      const params = { timeRange: filter, supplierId: selectedSupplier };
      if (selectedSupplier) params.supplierId = selectedSupplier;

      const response = await requestAPI({
        method: 'get',
        url: '/user-activity',
        params,
      });

      const responseData =
        response || response.data?.data || response.data || [];
      if (!Array.isArray(responseData)) {
        console.error('Invalid data format:', response);
        return;
      }

      const formattedData = responseData.map((item) => {
        let formattedDate = item.displayName;

        if (!isNaN(Date.parse(item.displayName))) {
          const dateObj = new Date(item.displayName);

          if (filter === 'last7days') {
            formattedDate = dateObj.toLocaleDateString('en-US', {
              weekday: 'short',
              day: 'numeric',
            });
          } else if (filter === 'last30days') {
            formattedDate = dateObj.toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
            });
          } else if (filter === 'last60days') {
            formattedDate = dateObj.toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
            });
          } else if (filter === 'last3months') {
            formattedDate = dateObj.toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
            });
          } else if (filter === 'year') {
            formattedDate = dateObj.toLocaleDateString('en-US', {
              month: 'short',
            });
          }
        }

        return {
          views: item.totalViews || 0,
          clicks: item.totalClicks || 0,
          date: formattedDate,
        };
      });
      setData(filter === 'year' ? formattedData : formattedData.reverse());
    } catch (error) {
      console.error('Error fetching product tracking data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [filter, selectedSupplier]);

  useEffect(() => {
    if (data.length > 0) {
      const totalViews = data.reduce((sum, item) => sum + (item.views || 0), 0);
      const totalClicks = data.reduce(
        (sum, item) => sum + (item.clicks || 0),
        0
      );
      const conversionRate: any =
        totalClicks > 0 ? ((totalClicks / totalViews) * 100).toFixed(2) : 0;

      setTotalValues({
        views: totalViews,
        clicks: totalClicks,
        conversionRate,
      });
    }
  }, [data]);

  return (
    <Container>
      <Title>Product Statistics</Title>

      <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
        <Select
          value={selectedSupplier}
          onChange={(e) => setSelectedSupplier(e.target.value)}
        >
          <option value="">All Suppliers</option>
          {suppliers.map((supplier) => (
            <option key={supplier._id} value={supplier._id}>
              {supplier.name}
            </option>
          ))}
        </Select>

        <Select value={filter} onChange={(e) => setFilter(e.target.value)}>
          <option value="last7days">Last 7 days</option>
          <option value="last30days">Last 30 days</option>
          <option value="last60days">Last 60 days</option>
          <option value="last3months">Last 3 Months</option>
          <option value="year">This Year</option>
        </Select>
      </div>

      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis
            domain={[0, 'dataMax']}
            tickFormatter={(value) => Math.floor(value).toLocaleString()}
          />
          <Tooltip
            formatter={(value, name) =>
              name === 'Conversion Rate' ? `${value}%` : value.toLocaleString()
            }
          />

          <Legend
            formatter={(value, entry) => {
              const { dataKey }: any = entry;
              if (dataKey === 'views') return `(${totalValues.views}) Views`;
              if (dataKey === 'clicks') return `(${totalValues.clicks}) Clicks`;
              if (dataKey === 'conversionRate')
                return `(${totalValues.conversionRate}%) Conversion Rate`;
              return value;
            }}
          />

          <Line
            type="monotone"
            dataKey="views"
            stroke="#8884d8"
            name="Views"
            activeDot={{ r: 5 }}
          />
          <Line
            type="monotone"
            dataKey="clicks"
            stroke="#82ca9d"
            name="Clicks"
            activeDot={{ r: 5 }}
          />
          <Line
            type="monotone"
            dataKey="conversionRate"
            stroke="#ff7300"
            name="Conversion Rate"
            dot={false}
            activeDot={{ r: 5 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default ProductChart;
