'use client';

import { OrgChart } from 'd3-org-chart';
import { isEqual, pick } from 'lodash';
import { useEffect, useRef, useState } from 'react';

import { ButtonTab } from '@/designComponents/ButtonTab';
import Modal from '@/designComponents/Modal';
import { ModalContentWrapper } from '@/designComponents/Modal/style';

import CreateDocuments from '../CreateDocument';
import CreateDocumentGroup from '../CreateDocumentGroup';
import {
  cardData,
  closeDropdown,
  findDocumentGroupById,
  flattenWithParentId,
  generateNodeHTML,
  toggleDropdown,
} from './utils';

export type InitialValuesType = {
  title: string;
  documentGroupId: string;
  hashTags: any;
  type: string;
  editorHtml: string;
  pdfFile?: File;
  iconFile?: File;
};

import { toast } from 'react-toastify';

import documents from '@/apiConfigs/documents';
import CountrySelection from '@/components/CountrySelection';
import DeleteModal from '@/components/DeleteModal';
import configs from '@/configs';
import { useRoot } from '@/context/RootProvider';
import axiosInstance from '@/utils/apiHelpers';
import requestAPI from '@/utils/requestAPI';

type Props = {
  isOpenModal: boolean;
  onClose: () => void;
};

const DocumentTree = ({ isOpenModal, onClose }: Props) => {
  const [activeTab, setActiveTab] = useState('go-green');
  const [pinnedGroupId, setPinnedGroupId] = useState(null);
  const { region: selectedCountry = 'ie', setRegion: setSelectedCountry } =
    useRoot();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [removeErrorMessage, setRemoveErrorMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [removeSuccessMessage, setRemoveSuccessMessage] = useState(false);

  const [data, setData] = useState({
    count: 0,
    data: null,
  });
  const [apiData, setAPIData] = useState(null);

  useEffect(() => {
    if (
      successMessage ||
      removeSuccessMessage ||
      errorMessage ||
      removeErrorMessage
    ) {
      const timer = setTimeout(() => {
        setSuccessMessage(false);
        setRemoveSuccessMessage(false);
        setErrorMessage(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [successMessage, removeSuccessMessage, errorMessage, removeErrorMessage]);

  const d3Container = useRef(null);
  const chart = useRef(new OrgChart());
  const [action, setAction] = useState<{
    id: string;
    actionType: string;
    type: string;
    title?: string;
    status?: string;
  }>({});

  const getFullSlugForTab = (tab: string) => {
    switch (tab) {
      case 'go-green':
        return 'full-tree-go-green';
      case 'regulation':
        return 'full-tree-regulation';
      case 'guideline':
        return 'full-tree-guideline';
      case 'construction-drawing':
        return 'full-tree-construction-drawing';
      case 'building-system':
        return 'full-tree-building-system';
      default:
        return '';
    }
  };

  const handleDataChange = (prevData, newData) => {
    const originalMap = new Map(prevData.map((item) => [item.id, item]));
    const processedIds = new Set();

    console.log('test fn', chart.current.addNode, chart.current.removeNode);

    const toPickList = [
      'fullSlug',
      'id',
      'parentId',
      'position',
      'refId',
      'slug',
      'status',
      'type',
      'title',
    ];
    newData.forEach((newItem) => {
      const oldItem = originalMap.get(newItem.id);

      if (!oldItem) {
        chart.current.addNode({ ...newItem, _centered: true });
      } else if (
        !isEqual(pick(oldItem, toPickList), pick(newItem, toPickList))
      ) {
        try {
          chart.current.removeNode(oldItem?.id).addNode({ ...newItem });
        } catch (err) {
          console.log('udpate err', err, oldItem, newItem);
        }
      }

      processedIds.add(newItem.id);
    });
    prevData.forEach((oldItem) => {
      if (!processedIds.has(oldItem.id)) {
        try {
          chart.current.removeNode(oldItem.id);
        } catch (err) {
          console.log('delete err', err);
        }
      }
    });

    chart.current.render();
  };

  const fetchData = async () => {
    const {
      data: { data: resData },
    } = await axiosInstance.get(`${configs.apiURL}/sidebar/${activeTab}/tree`, {
      headers: {
        Region: selectedCountry,
      },
    });

    return resData;
  };

  const loadData = async () => {
    const loadedData = await fetchData();

    setAPIData({ ...loadedData });
    setTimeout(() => {
      const flattenData = flattenWithParentId(loadedData, '');
      chart.current
        .nodeHeight((d) => 80)
        .nodeWidth((d) => 220)
        .childrenMargin((d) => 50)
        .compactMarginBetween((d) => 35)
        .compact(false)
        .compactMarginPair((d) => 30)
        .neighbourMargin((a, b) => 20)
        .nodeContent((d, i, arr, state) => generateNodeHTML(d))
        .container(d3Container.current)
        .data(flattenData)
        .render();
      setData({ count: 0, data: flattenData });
    }, 1000);
  };

  const pinToTop = (documentGroupId, treeData) => {
    //TODO: fix hacky
    setAPIData((prev) => {
      const tree = findDocumentGroupById(treeData || prev, documentGroupId);
      if (tree) {
        setPinnedGroupId(documentGroupId);
        const flattenData = flattenWithParentId(tree, '');
        if (!treeData) {
          chart.current.data(flattenData).render().fit();
        } else {
          handleDataChange(data.data, flattenData);
        }
        setData((prevData) => ({
          count: prevData.count + 1,
          data: flattenData,
        }));
      }

      return prev;
    });
  };

  const unPin = () => {
    const flattenData = flattenWithParentId(apiData, '');
    chart.current.data(flattenData).render().fit();
    setData((prevData) => ({
      count: prevData.count + 1,
      data: flattenData,
    }));
  };

  useEffect(() => {
    loadData();
  }, [activeTab, selectedCountry]);

  const refreshData = async () => {
    const refreshedData = await fetchData();
    setAPIData(refreshedData);

    if (pinnedGroupId) {
      pinToTop(pinnedGroupId, refreshedData);
    } else {
      const flattenData = flattenWithParentId(refreshedData, '');
      handleDataChange(data.data, flattenData);
      setData((prevData) => ({
        count: prevData.count + 1,
        data: flattenData,
      }));
    }
  };

  const handleMarkAsTopic = async (refId: string, tabName: string) => {
    try {
      const fullSlug = getFullSlugForTab(tabName);

      setLoading(true);

      const payload = {
        documentGroupId: refId,
        fullSlug: fullSlug,
      };

      await requestAPI(
        documents.markAsATopic(payload, {
          headers: {
            region: selectedCountry,
          },
        })
      );

      setLoading(false);
      loadData();
      setSuccessMessage(true);
    } catch (error: any) {
      setLoading(false);
      console.error('Something went wrong', error.message);
      toast.error(error.message);
      setErrorMessage(error.message);
    }
  };

  const handleRemoveTopic = async (refId: string, tabName: string) => {
    try {
      const fullSlug = getFullSlugForTab(tabName);

      setLoading(true);

      const payload = {
        documentGroupId: refId,
        fullSlug: fullSlug,
      };

      await requestAPI(
        documents.removeATopic(payload, {
          headers: {
            region: selectedCountry,
          },
        })
      );

      setLoading(false);
      setRemoveSuccessMessage(true);
      loadData();
    } catch (error: any) {
      setLoading(false);
      console.error('Something went wrong', error.message);
      // toast.error(error.message);
      setRemoveErrorMessage(error.message);
    }
  };

  const handleActionClick = ({
    refId,
    actionType,
    type,
  }: {
    refId: string;
    actionType: string;
    type: string;
  }) => {
    setData((prev) => {
      const currentItem = prev.data.find((item) => item.id === refId);

      switch (actionType) {
        case 'edit': {
          setAction({
            type,
            actionType,
            id: refId,
            title: currentItem?.title,
            status: currentItem?.status,
          });

          break;
        }
        case 'add-child-group': {
          setAction({
            type,
            actionType,
            id: refId,
            title: currentItem?.title,
            status: currentItem?.status,
          });

          break;
        }
        case 'delete-child-group': {
          setAction({
            type,
            actionType,
            id: refId,
            title: currentItem?.title,
            status: currentItem?.status,
          });

          break;
        }
        case 'delete-document': {
          setAction({
            type,
            actionType,
            id: refId,
            title: currentItem?.title,
            status: currentItem?.status,
          });

          break;
        }
        case 'add-child-document': {
          setAction({
            type,
            actionType,
            id: refId,
            title: currentItem?.title,
            status: currentItem?.status,
          });

          break;
        }
        case 'pin': {
          pinToTop(refId);
          break;
        }
        case 'mark-as-topic': {
          // Pass the current activeTab value explicitly
          const currentTab = activeTab;

          handleMarkAsTopic(refId, currentTab);
          break;
        }
        case 'remove-topic': {
          // Pass the current activeTab value explicitly
          const currentTab = activeTab;
          handleRemoveTopic(refId, currentTab);
          break;
        }
      }

      return prev;
    });
  };

  const removeEventListeners = (elements, event, handler) => {
    elements.forEach((element) => {
      element.removeEventListener(event, handler);
    });
  };

  const handleDropdownClick = (event) => {
    toggleDropdown(event);
  };

  const handleMenuClick = (e) => {
    const refId = e.target.getAttribute('data-ref-id');
    const actionType = e.target.getAttribute('data-action-type');
    const type = e.target.getAttribute('data-type');

    handleActionClick({
      refId,
      actionType,
      type,
    });
  };

  const loadListeners = () => {
    const dropdownButtons = document.querySelectorAll('.chart-dropdown-button');
    const actionButtons = document.querySelectorAll('.chart-dropdown-action');

    removeEventListeners(dropdownButtons, 'click', handleDropdownClick);
    removeEventListeners(actionButtons, 'click', handleMenuClick);

    setTimeout(() => {
      dropdownButtons.forEach((button) => {
        button.addEventListener('click', handleDropdownClick);
      });

      actionButtons.forEach((button) => {
        button.addEventListener('click', handleMenuClick);
      });
    }, 200);
  };

  useEffect(() => {
    window.addEventListener('click', closeDropdown);

    return () => {
      window.removeEventListener('click', closeDropdown);
    };
  }, []);

  useEffect(() => {
    if (d3Container?.current) {
      const handleDomChange = () => {
        loadListeners();
      };

      const observer = new MutationObserver(handleDomChange);

      observer.observe(d3Container.current, {
        attributes: false,
        childList: true,
        subtree: true,
      });

      return () => {
        observer.disconnect();
      };
    }
  }, [d3Container?.current, apiData]);

  const handleDeleteDocumentGroup = async () => {
    try {
      setLoading(true);
      await requestAPI(documents.deleteChildDocuments(action.id));
      setLoading(false);
      refreshData();
      setAction({});
      toast.success('Document Group deleted successfully');
    } catch (error: any) {
      setLoading(false);
      console.error('Error while deleting the document', error.message);
      toast.error(error.message);
    }
  };
  const handleDeleteDocument = async () => {
    try {
      setLoading(true);
      await requestAPI(documents.deleteDocument(action.id));
      setLoading(false);
      refreshData();
      setAction({});
      toast.success('Document deleted successfully');
    } catch (error: any) {
      setLoading(false);
      console.error('Error while deleting the document', error.message);
      if (
        error.response ||
        error.response.data ||
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
      toast.error(error.message);
    }
  };

  console.log('chart.current', chart.current);

  return (
    <>
      <Modal
        isOpen={isOpenModal}
        onClose={onClose}
        title="Document Tree"
        HeaderComponent={() => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 20,
              paddingTop: 4,
              marginBottom: -12,
            }}
          >
            <ButtonTab
              items={cardData}
              activeIndex={cardData.findIndex((x) => x.key === activeTab)}
              setActiveIndex={(index) => {
                setPinnedGroupId(null);
                setActiveTab(cardData[index].key);
              }}
            />
            {!!pinnedGroupId && (
              <ButtonTab
                items={[
                  {
                    title: 'Pinned',
                  },
                ]}
                activeIndex={0}
                setActiveIndex={() => {
                  setPinnedGroupId(null);
                  unPin();
                }}
              />
            )}
            <CountrySelection
              selectedCountry={selectedCountry}
              setSelectedCountry={async (val) => {
                if (val === 'master') {
                  setSelectedCountry(undefined);
                } else {
                  setSelectedCountry(val);
                }
              }}
            />
          </div>
        )}
      >
        <ModalContentWrapper
          style={{
            maxHeight: 'calc(100vh - 80px)',
            height: 'calc(100vh - 80px)',
            width: '100vw',
            userSelect: 'none',
          }}
        >
          <div>
            <div
              style={{
                display: 'flex',
                gap: 10,
                marginBottom: 10,
              }}
            >
              {/* <button
                onClick={() => chart.current?.expandAll().fit()}
                style={{
                  padding: '5px 10px',
                  borderWidth: 1,
                  borderRadius: 5,
                }}
              >
                Expand All
              </button> */}
              <button
                onClick={() => {
                  const selectedId = chart.current
                    ?.data()
                    .map((item) => item.id)[2];
                  // chart.current?.collapseAll().fit();

                  if (selectedId) {
                    chart.current
                      ?.setExpanded(selectedId, false)
                      .render()
                      .fit();
                  } else {
                    chart.current?.collapseAll().fit();
                  }
                }}
                style={{
                  padding: '5px 10px',
                  borderWidth: 1,
                  borderRadius: 5,
                }}
              >
                Collapse All
              </button>
            </div>
            {errorMessage && (
              <div
                style={{
                  width: '100%',
                  // textAlign: 'center',
                  // backgroundColor: '#882D2E',
                  border: '2px solid red',
                  padding: '10px',
                  borderRadius: '5px',
                }}
              >
                <p style={{ color: 'red' }}>{errorMessage}</p>
              </div>
            )}
            {removeErrorMessage && (
              <div
                style={{
                  width: '100%',
                  // textAlign: 'center',
                  // backgroundColor: '#882D2E',
                  border: '2px solid red',
                  padding: '10px',
                  borderRadius: '5px',
                }}
              >
                <p style={{ color: 'red' }}>{removeErrorMessage}</p>
              </div>
            )}
            {successMessage && (
              <div
                style={{
                  width: '100%',

                  border: '2px solid green',
                  padding: '10px',
                  borderRadius: '5px',
                }}
              >
                <p style={{ color: 'green' }}>Topic added successfully</p>
              </div>
            )}
            {removeSuccessMessage && (
              <div
                style={{
                  width: '100%',

                  border: '2px solid green',
                  padding: '10px',
                  borderRadius: '5px',
                }}
              >
                <p style={{ color: 'green' }}>Topic removed successfully</p>
              </div>
            )}
            <div
              ref={d3Container}
              style={{
                marginTop: 10,
              }}
            />

            {['add-child-group'].includes(action?.actionType) &&
              action?.type === 'document-group' && (
                <CreateDocumentGroup
                  parentId={action.id}
                  parentTitle={action.title}
                  onClose={() => {
                    setAction({});
                    refreshData();
                  }}
                  countryRegion={selectedCountry}
                  isOpen
                  type={activeTab}
                  status={action.status}
                />
              )}

            {action?.actionType === 'add-child-document' &&
              action?.type === 'document-group' && (
                <CreateDocuments
                  documentGroupId={action.id} //for parent list
                  documentGroupTitle={action.title}
                  countryRegion={selectedCountry}
                  isOpenModal
                  type={activeTab}
                  status={action.status}
                  onClose={() => {
                    setAction({});
                    refreshData();
                  }}
                />
              )}
            {action?.actionType === 'delete-child-group' &&
              action?.type === 'document-group' && (
                <DeleteModal
                  deleteText="Are you sure want to delete this document group ?"
                  isOpen
                  onClose={() => {
                    setAction({});
                  }}
                  confirmBtnLoading={loading}
                  onConfirmDelete={handleDeleteDocumentGroup}
                />
              )}
            {action?.actionType === 'delete-document' &&
              action?.type === 'document' && (
                <DeleteModal
                  deleteText="Are you sure want to delete this document ?"
                  isOpen
                  confirmBtnLoading={loading}
                  onClose={() => {
                    setAction({});
                  }}
                  onConfirmDelete={handleDeleteDocument}
                />
              )}

            {['edit'].includes(action.actionType) &&
              action.type === 'document-group' && (
                <CreateDocumentGroup
                  documentGroupId={action.id}
                  isOpen
                  countryRegion={selectedCountry}
                  onClose={() => {
                    setAction({});
                    refreshData();
                  }}
                  status={action.status}
                />
              )}

            {action.actionType === 'edit' &&
              action.type !== 'document-group' &&
              action.type !== 'document' && (
                <CreateDocumentGroup
                  documentGroupId={action.id}
                  isOpen
                  countryRegion={selectedCountry}
                  onClose={() => {
                    setAction({});
                    refreshData();
                  }}
                  status={action.status}
                />
              )}

            {action?.actionType === 'edit' && action?.type === 'document' && (
              <CreateDocuments
                countryRegion={selectedCountry}
                documentId={action.id}
                isOpenModal
                type={activeTab}
                onClose={() => {
                  setAction({});
                  refreshData();
                }}
                status={action.status}
              />
            )}
          </div>
        </ModalContentWrapper>
      </Modal>
    </>
  );
};

export default DocumentTree;
